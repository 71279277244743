// extracted by mini-css-extract-plugin
export var heroBackground = "about-module--heroBackground--1k-6Q";
export var heroImageClass = "about-module--heroImageClass--IOTbS";
export var heroTitleCont = "about-module--heroTitleCont--gyJJL";
export var ourVisionCard = "about-module--ourVisionCard--3b29y";
export var missionTitle = "about-module--missionTitle--xgf0R";
export var missionCard = "about-module--missionCard--1qxqW";
export var missionRow = "about-module--missionRow--3CaCC";
export var featuredTeamCard = "about-module--featuredTeamCard--3hdx2";
export var featuredTeamDescription = "about-module--featuredTeamDescription--C3vbn";
export var teamMiniCard = "about-module--teamMiniCard--1RAQK";
export var selectedTeam = "about-module--selectedTeam--2adqn";
export var missionContainer = "about-module--missionContainer--2ksgd";
export var customCol = "about-module--customCol--kHBc0";