import React, { useState, useRef } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import {
  heroBackground,
  heroImageClass,
  heroTitleCont,
  ourVisionCard,
  missionTitle,
  missionCard,
  missionRow,
  featuredTeamCard,
  featuredTeamDescription,
  teamMiniCard,
  missionContainer,
  customCol
} from "../styles/about.module.scss"
import { Container, Row, Col } from "react-bootstrap"
import { VscGlobe, VscMortarBoard, VscOrganization, VscThumbsup } from "react-icons/vsc"
import Slider from "react-slick";
import { getImage } from "gatsby-plugin-image"
import { BgImage } from 'gbimage-bridge';

// import aalmadori from "../images/aurora-almadori.png"
// import bLandridge from "../images/ben-landridge.jpg"
// import lAwad from "../images/laura-awad.jpg"
// import mGriffin from "../images/michelle-griffin.jpg"
// import sSpeiser from "../images/sophie-speiser.jpg"
// import faithHkj from "../images/faith-hkj.jpg"


const SecondPage = (pageData) => {
  console.log(pageData)

  const teamMembers = pageData.data.allContentfulTeamMember.nodes
  const heroImage = getImage(pageData.data.heroImage.childImageSharp.gatsbyImageData)
  const sliderRef = useRef();

  const [currSlide, setCurrSlide] = useState(0)

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    pauseOnHover: true,
    autoplay: true,
    autoplaySpeed: 15000,
    afterChange: (event, slick, currentSlide) => {
      setCurrSlide(event)
      console.log(currSlide, event, slick, currentSlide)
    },
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          centreMode: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
        }
      }
    ]
  };

  const sliderGoTo = (index) => {
    console.log(sliderRef)
    sliderRef.current.slickGoTo(index)
  }

  return (
    <Layout>
      <Seo title="About" />

      <section>
        <div className={heroBackground}>

          <BgImage image={heroImage} className={heroImageClass}>
            <div style={{
              width: '100%',
              height: '100%',
              top: 0,
              position: 'absolute',
              backgroundImage: `radial-gradient(circle at 100% 100%, rgba(240, 59, 59, 0.20) 15%, #EF0E0E 82%)`
            }}>

            </div>
          </BgImage>

          
        </div>
      </section>

      <section style={{ background: 'white' }}>
        <svg width="100%" height="250" viewBox="0 0 500 300" preserveAspectRatio="none" className="background-svg" style={{ marginTop: '-15rem' }}>
          <path d="M0,300 L0,0 Q200,280 500,250 L500, 300 Z" fill="white" />
        </svg>

        <div className={heroTitleCont}>
            <Container>
              <Row>
                <Col sm={12} md={12} lg={{ span: 10, offset: 1 }} xl={{ span: 6, offset: 0 }} className={customCol}>
                  <div className={`${ourVisionCard} card`} style={{
                    zIndex: 2
                  }}>
                    <h1>The Vision</h1>
                    <div className="title-highlight" style={{
                      width: '30%',
                      marginBottom: '2rem'
                    }}></div>
                    <p>
                      The Charles Wolfson Centre is a national medical centre of excellence for reconstructive surgery. We are located in London, with a global vision.<br /><br />The centre unites world leading experts within the field of reconstructive surgery, and invites multidisciplinary collaboration, to together create positive changes throughout education, research and clinical practice.<br /><br />Our work will help to surgically train and educate generations of surgeons who will be equipped with the skills to deliver world class outcomes for reconstructive surgery patients.
                  </p>
                  </div>
                </Col>
              </Row>

            </Container>
          </div>

        <Container className={missionContainer}>
          <Row className="justify-content-center">
            <Col xs={10} md={8} lg={6}>
              <div className={missionTitle}>
                <h2 className="text-center">The Mission</h2>
                <div className="title-highlight" style={{
                  width: '30%',
                  margin: 'auto',
                  marginBottom: '1.5rem'
                }}></div>
                <p className="text-center">
                  Our aspirations above will be built upon through 3 main branches of the Charles Wolfson Centre:
              </p>
              </div>
            </Col>
          </Row>

          <Row className={`${missionRow} align-items-center`}>
            <Col xs={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 0 }} className="d-flex justify-content-center">
              <VscOrganization size={80} />
            </Col>
            <Col xs={10} lg={7}>
              <div className={`${missionCard} card`}>Innovative and collaborative research to tackle todays challenges in reconstructive surgery.</div>
            </Col>
          </Row>
          <Row className={`${missionRow} align-items-center`}>
            <Col xs={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 1 }} className="d-flex justify-content-center">
              <VscMortarBoard size={80} />
            </Col>
            <Col xs={10} lg={7}>
              <div className={`${missionCard} card`}>Education of health care professionals, who hold both clinical and academic roles.</div>
            </Col>
          </Row>
          <Row className={`${missionRow} align-items-center`}>
            <Col xs={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 2 }} className="d-flex justify-content-center">
              <VscGlobe size={80} />
            </Col>
            <Col xs={10} lg={7}>
              <div className={`${missionCard} card`}>To provide learning and educational material, which is widely available on a global level.</div>
            </Col>
          </Row>
          <Row className={`${missionRow} align-items-center`}>
            <Col xs={{ span: 2, offset: 0 }} lg={{ span: 2, offset: 3 }} className="d-flex justify-content-center">
              <VscThumbsup size={80} />
            </Col>
            <Col xs={10} lg={7}>
              <div className={`${missionCard} card`}>Commitment and openness with patients and general public, to improve awareness and quality of life.</div>
            </Col>
          </Row>

        </Container>
        <svg width="100%" height="150" viewBox="0 0 500 120" preserveAspectRatio="none" className="background-svg" style={{ background: '#FAFAFA' }}>
          <path d="M0,0 L0,40 Q250,120 500,40 L500,0 Z" fill="white" />
        </svg>


      </section>

      <section>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={8} md={6}>
              <div className={missionTitle}>
                <h2 className="text-center">The Team</h2>
                <div className="title-highlight" style={{
                  width: '30%',
                  margin: 'auto',
                  marginBottom: '1.5rem'
                }}></div>
              </div>
            </Col>
          </Row>

          <Row className="align-items-center" style={{ marginTop: '2rem' }}>
            <Col md={{ span: 3, offset: 0 }} lg={{ span: 3, offset: 1 }} className="d-flex justify-content-center">


              <BgImage image={getImage(teamMembers[currSlide].image.gatsbyImageData)} className={`${featuredTeamCard} card`}>
              </BgImage>


            </Col>
            <Col md={{ span: 9 }} lg={{ span: 7 }}>
              <div className={featuredTeamDescription}>
                <h2>{teamMembers[currSlide].name}</h2>
                <p>{teamMembers[currSlide].description.description}</p>
              </div>
            </Col>
          </Row>
        </Container>
        <div style={{overflow: 'hidden', paddingTop: '2rem'}}>
          <Container>
            <Row className="justify-content-center">
              <Col sm={{ span: 10, offset: 1 }}>
                <Slider {...settings} style={{ marginBottom: '2rem' }} ref={sliderRef}>
                  {
                    teamMembers.map((member, index) => {
                      const image = getImage(member.image.gatsbyImageData)
                      return (
                        <div key={index} onClick={() => sliderGoTo(index)}>
                          <BgImage image={image} className={`${teamMiniCard} card`}>
                          </BgImage>
                        </div>
                      )
                    })
                  }

                </Slider>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

    </Layout >
  )
}

export const pageQuery = graphql`
  {
  allContentfulTeamMember(sort: {fields: appearanceOrder, order: ASC}) {
    nodes {
      appearanceOrder
      name
      image {
        gatsbyImageData (
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
      description {
        description
      }
    }
  }
  heroImage: file(relativePath: {eq: "royal-free-hospital.jpeg"}) {
    childImageSharp {
			gatsbyImageData(
        formats: [AUTO, WEBP]
        placeholder: BLURRED
      )
    }
  }
}
`

export default SecondPage
